const media = {
    //Images
    ignition_logo_normal: require("./imgs/ignition_logo_normal.svg").default,
    ignition_logo_white: require("./imgs/ignition_logo_white.svg").default,
    ignition_bg_solid: require("./imgs/ignition_bg_solid.svg").default,

    home_illustration1: require("./imgs/home_illustration1.svg").default,
    home_illustration2: require("./imgs/home_illustration2.svg").default,

    client_toyota: require("./imgs/clients/toyota.svg").default,
    client_lexus: require("./imgs/clients/lexus.svg").default,
    client_yamaha: require("./imgs/clients/yamaha.svg").default,
    client_renault: require("./imgs/clients/renault.svg").default,
    client_hyundai: require("./imgs/clients/hyundai.svg").default,
    client_topgear: require("./imgs/clients/topgear.svg").default,
    client_renaultgroup: require("./imgs/clients/renaultgroup.svg").default,
    client_dacia: require("./imgs/clients/dacia.svg").default,
    client_bbc: require("./imgs/clients/bbc.svg").default,
    client_macallan: require("./imgs/clients/macallan.svg").default,
    client_subaru: require("./imgs/clients/subaru.svg").default,
    client_nissan: require("./imgs/clients/nissan.svg").default,
    client_landrover: require("./imgs/clients/landrover.svg").default,
    client_bentley: require("./imgs/clients/bentley.svg").default,
    client_jaguar: require("./imgs/clients/jaguar.svg").default,
    client_puig: require("./imgs/clients/puig.svg").default,
    client_loreal: require("./imgs/clients/loreal.svg").default,
    client_radisson: require("./imgs/clients/radisson.svg").default,
    client_danwood: require("./imgs/clients/danwood.svg").default,
    client_collinson: require("./imgs/clients/collinson.svg").default,

    member_alice: require("./imgs/members/alice.png"),
    member_antony: require("./imgs/members/antony.png"),
    member_aston: require("./imgs/members/aston.png"),
    member_daniella: require("./imgs/members/daniella.png"),
    member_diego: require("./imgs/members/diego.png"),
    member_ellie: require("./imgs/members/ellie.png"),
    member_georgie: require("./imgs/members/georgie.png"),
    member_ivo: require("./imgs/members/ivo.png"),
    member_jason: require("./imgs/members/jason.png"),
    member_leon: require("./imgs/members/leon.png"),
    member_luka: require("./imgs/members/luka.png"),
    member_mattc: require("./imgs/members/mattc.png"),
    member_matts: require("./imgs/members/matts.png"),
    member_pello: require("./imgs/members/pello.png"),
    member_richb: require("./imgs/members/richb.png"),
    member_richf: require("./imgs/members/richf.png"),
    member_rob: require("./imgs/members/rob.png"),
    member_rory: require("./imgs/members/rory.png"),
    member_simon: require("./imgs/members/simon.png"),
    member_teddy: require("./imgs/members/teddy.png"),
    member_vicki: require("./imgs/members/vicki.png"),
    member_xanthe: require("./imgs/members/xanthe.png"),

    services_illustration1: require("./imgs/services_illustration1.svg").default,
    services_illustration2: require("./imgs/services_illustration2.svg").default,
    services_skill1a: require("./imgs/services_skill1a.png"),
    services_skill1b: require("./imgs/services_skill1b.png"),
    services_skill1c: require("./imgs/services_skill1c.png"),
    services_skill1d: require("./imgs/services_skill1d.png"),
    services_skill2a: require("./imgs/services_skill2a.png"),
    services_skill2b: require("./imgs/services_skill2b.png"),
    services_skill2c: require("./imgs/services_skill2c.png"),
    services_skill2d: require("./imgs/services_skill2d.png"),
    services_skill3a: require("./imgs/services_skill3a.png"),
    services_skill3b: require("./imgs/services_skill3b.png"),
    services_skill3c: require("./imgs/services_skill3c.png"),
    services_skill3d: require("./imgs/services_skill3d.png"),
    services_skill4a: require("./imgs/services_skill4a.png"),
    services_skill4b: require("./imgs/services_skill4b.png"),
    services_skill4c: require("./imgs/services_skill4c.png"),
    services_skill4d: require("./imgs/services_skill4d.png"),

    about_illustration1: require("./imgs/about_illustration1.svg").default,
    about_illustration2: require("./imgs/about_illustration2.svg").default,
    about_award1: require("./imgs/awards/award1.png"),
    about_award2: require("./imgs/awards/award2.png"),
    about_award3: require("./imgs/awards/award3.png"),
    about_award4: require("./imgs/awards/award4.png"),
    about_award5: require("./imgs/awards/award5.png"),
    about_award6: require("./imgs/awards/award6.png"),
    about_award7: require("./imgs/awards/award7.png"),
    about_award8: require("./imgs/awards/award8.png"),
    about_award9: require("./imgs/awards/award9.png"),
    about_award10: require("./imgs/awards/award10.png"),
    about_award11: require("./imgs/awards/award11.png"),
    about_award12: require("./imgs/awards/award12.png"),
    about_award13: require("./imgs/awards/award13.png"),
    about_award14: require("./imgs/awards/award14.png"),
    about_award15: require("./imgs/awards/award15.png"),
    about_award16: require("./imgs/awards/award16.png"),
    about_award17: require("./imgs/awards/award17.png"),

    blog_thumb: require("./imgs/blog_thumb.jpg"),

    afvs_thumb: require("./imgs/works/afvs/thumb.jpg"),
    afvs_bg: require("./imgs/works/afvs/bg.jpg"),
    afvs_img1: require("./imgs/works/afvs/img1.jpg"),
    afvs_img2: require("./imgs/works/afvs/img2.jpg"),
    afvs_img3: require("./imgs/works/afvs/img3.jpg"),

    arkana_thumb: require("./imgs/works/arkana/thumb.jpg"),
    arkana_bg: require("./imgs/works/arkana/bg.jpg"),
    arkana_img1: require("./imgs/works/arkana/img1.jpg"),
    arkana_img2: require("./imgs/works/arkana/img2.jpg"),
    arkana_img3: require("./imgs/works/arkana/img3.jpg"),

    chr_thumb: require("./imgs/works/chr/thumb.jpg"),
    chr_bg: require("./imgs/works/chr/bg.jpg"),
    chr_img1: require("./imgs/works/chr/img1.jpg"),
    chr_img2: require("./imgs/works/chr/img2.jpg"),
    chr_img3: require("./imgs/works/chr/img3.jpg"),

    grmodels_thumb: require("./imgs/works/gr-models/thumb.jpg"),
    grmodels_bg: require("./imgs/works/gr-models/bg.jpg"),
    grmodels_img1: require("./imgs/works/gr-models/img1.jpg"),
    grmodels_img2: require("./imgs/works/gr-models/img2.jpg"),
    grmodels_img3: require("./imgs/works/gr-models/img3.jpg"),

    ioniq6_thumb: require("./imgs/works/ioniq6/thumb.jpg"),
    ioniq6_bg: require("./imgs/works/ioniq6/bg.jpg"),
    ioniq6_img1: require("./imgs/works/ioniq6/img1.jpg"),
    ioniq6_img2: require("./imgs/works/ioniq6/img2.jpg"),
    ioniq6_img3: require("./imgs/works/ioniq6/img3.jpg"),

    sunthesia_thumb: require("./imgs/works/synthesia/thumb.jpg"),
    synthesia_bg: require("./imgs/works/synthesia/bg.jpg"),
    synthesia_img1: require("./imgs/works/synthesia/img1.jpg"),
    synthesia_img2: require("./imgs/works/synthesia/img2.jpg"),
    synthesia_img3: require("./imgs/works/synthesia/img3.jpg"),

    hybrid_thumb: require("./imgs/works/hybrid/thumb.jpg"),
    hybrid_bg: require("./imgs/works/hybrid/bg.jpg"),
    hybrid_img1: require("./imgs/works/hybrid/img1.jpg"),
    hybrid_img2: require("./imgs/works/hybrid/img2.jpg"),
    hybrid_img3: require("./imgs/works/hybrid/img3.jpg"),

    gdpr_thumb: require("./imgs/works/gdpr/thumb.jpg"),
    gdpr_bg: require("./imgs/works/gdpr/bg.jpg"),
    gdpr_img1: require("./imgs/works/gdpr/img1.jpg"),
    gdpr_img2: require("./imgs/works/gdpr/img2.jpg"),
    gdpr_img3: require("./imgs/works/gdpr/img3.jpg"),

    vehiclewraps_thumb: require("./imgs/works/vehicle-wraps/thumb.jpg"),
    vehiclewraps_bg: require("./imgs/works/vehicle-wraps/bg.jpg"),
    vehiclewraps_img1: require("./imgs/works/vehicle-wraps/img1.jpg"),
    vehiclewraps_img2: require("./imgs/works/vehicle-wraps/img2.jpg"),
    vehiclewraps_img3: require("./imgs/works/vehicle-wraps/img3.jpg"),

    gryaris_thumb: require("./imgs/works/gr-yaris/thumb.jpg"),
    gryaris_bg: require("./imgs/works/gr-yaris/bg.jpg"),
    gryaris_img1: require("./imgs/works/gr-yaris/img1.jpg"),
    gryaris_img2: require("./imgs/works/gr-yaris/img2.jpg"),
    gryaris_img3: require("./imgs/works/gr-yaris/img3.jpg"),

    digitaltransformation_thumb: require("./imgs/works/digital-transformation/thumb.jpg"),
    digitaltransformation_bg: require("./imgs/works/digital-transformation/bg.jpg"),
    digitaltransformation_img1: require("./imgs/works/digital-transformation/img1.jpg"),
    digitaltransformation_img2: require("./imgs/works/digital-transformation/img2.jpg"),
    digitaltransformation_img3: require("./imgs/works/digital-transformation/img3.jpg"),

    //Video
    showreel_video: require("./video/showreel.mp4"),
    culture_video: require("./video/culture.mp4")
}

export default media;